import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, colors, radius, fontSizes, space, lineHeights } from 'theme'
import {
  CONTAINER_MAX_WIDTH,
  FREQUENTLY_ASKED_QUESTIONS,
  DOWNLOAD_APP_GOOGLE_LINK,
  DOWNLOAD_APP_APPLE_LINK,
  SCHEDULE_DEMO_LINK,
} from 'consts'
import { pxToRem } from 'helpers'
import SEO from 'components/SEO'
import { Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2, H3 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import Button from 'components/Button'

import imageHeader from 'static/images/solutions_creators/header_picture.webp'
import imageSocialMediaTools from 'static/images/solutions_creators/social_media_tools.webp'
import imageContentPlanningTools from 'static/images/solutions_creators/content_planning_tools.webp'
import imageContentPlanningCreateShareGrow from 'static/images/solutions_creators/create_share_grow.webp'
import imageReviewPerson from 'static/images/solutions_creators/review_person.webp'
import downloadGoogle from 'static/images/solutions_creators/download_google_play.svg'
import downloadIOS from 'static/images/solutions_creators/download_app_store.svg'

import creatorsInfluencersImageConnect from 'static/images/solutions_creators/social_media_tools/feed.svg'
import creatorsInfluencersImagePost from 'static/images/solutions_creators/social_media_tools/post.svg'
import creatorsInfluencersImageSchedule from 'static/images/solutions_creators/social_media_tools/schedule.svg'
import creatorsInfluencersImageInsights from 'static/images/solutions_creators/social_media_tools/insights.svg'

import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'

import SocialsIntegrations from '../components/SocialsIntegrations'
import GridDropdown from '../../Faq/GridDropdown'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const StyledGetStartedButton = styled(Button.Reversed)`
  font-size: ${fontSizes.m};
  height: 100%;
  padding-left: ${pxToRem(48)};
  padding-right: ${pxToRem(48)};
  box-shadow: 0px 18px 24px -8px rgba(216, 233, 255, 0.32);
`

const StyledTryVistaSocialButton = styled(Button.Reversed)`
  font-size: ${fontSizes.m};
  height: 100%;
  padding-left: ${pxToRem(48)};
  padding-right: ${pxToRem(48)};
  box-shadow: 0px 18px 20px -8px rgba(12, 35, 68, 0.16);
  background: ${COLOR_CONSTANTS.DENIM};
  color: ${colors.white};
  &:hover {
    background: ${colors.white};
    border: 1px solid ${COLOR_CONSTANTS.DENIM};
    color: ${COLOR_CONSTANTS.DENIM};
  }
`

const DownloadImg = styled(LocalImage)`
  padding-right: ${space.xs};
  padding-left: ${space.xs};
  cursor: pointer;
`

const TrialButton = styled(Button.Gradient)`
  font-size: ${fontSizes.m};
  height: 100%;
  padding-left: ${pxToRem(24)};
  padding-right: ${pxToRem(24)};
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
  margin-right: 10px;
`

const StyledScheduleDemoButton = styled(Button.Reversed)`
  font-size: ${fontSizes.m};
  height: 100%;
  padding-left: ${pxToRem(24)};
  padding-right: ${pxToRem(24)};
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
`

const CREATORS_INFLUENCERS_ITEMS = [
  {
    picture_url: creatorsInfluencersImageConnect,
    title: 'Create the perfect feed',
    description: 'Preview & rearrange scheduled posts with the Visual Planner to find the perfect feel for your feed.',
  },
  {
    picture_url: creatorsInfluencersImagePost,
    title: 'Post on the go',
    description: 'Create content on the go, then post it from your phone or desktop',
  },
  {
    picture_url: creatorsInfluencersImageSchedule,
    title: 'Schedule on all platforms',
    description:
      'Post content on all major social networks: TikTok, Facebook, Instagram, LinkedIn, Pinterest, Reddit, X (Twitter) and YouTube!',
  },
  {
    picture_url: creatorsInfluencersImageInsights,
    title: 'Get insights',
    description: 'Quickly evaluate and optimize social efforts, maximize results and prove value',
  },
]

const CONTENT_PLANNING_TOOLS_ITEMS = [
  {
    title: 'Drag & Drop scheduling',
    description: 'Add more content into your feed by simply dragging and dropping new media.',
  },
  {
    title: 'Queuing of posts',
    description: 'Set posting schedules based on provided optimal time analysis for faster scheduling.',
  },
  {
    title: 'Easy to rearrange content',
    description: 'Visually rearrange feed to create a perfect display.',
  },
]

const SolutionsCreators = () => {
  return (
    <App fullHeader>
      <SEO
        title="Vista Social for creators"
        description="One powerful platform, multiple ways to share your content. Vista Social tools for creators help you get noticed by brands for collaborations, engage with followers, sell your products, stay up to date on trends, and manage your social media content on the go."
        path="/creators/"
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              pt="l"
              mt="xl"
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
              position="relative"
            >
              <Flex height="100%" width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column" maxWidth={{ mobile: '100%', desktop: '485px' }}>
                  <H1
                    fontSize="4xl"
                    fontWeight="bold"
                    color={COLOR_CONSTANTS.DENIM}
                    textAlign={{ mobile: 'center', desktop: 'left' }}
                  >
                    Social Media Tools For{' '}
                    <H1
                      as="span"
                      fontSize="3xl"
                      fontWeight="bold"
                      color="primary"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                    >
                      Creators & Influencers&nbsp;✨
                    </H1>
                  </H1>
                  <Flex mt="m" pt="s">
                    <H2
                      color="secondaryText"
                      fontSize="m"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                      lineHeight={lineHeights.xxl}
                    >
                      One powerful platform, multiple ways to share your content. Vista Social tools for creators help
                      you get noticed by brands for collaborations, engage with followers, sell your products, stay up
                      to date on trends, and manage your social media content on the go.
                    </H2>
                  </Flex>

                  <Flex mt="m" pt="m" mb="m">
                    <Flex
                      height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}
                      width="100%"
                      justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                    >
                      <TrialButton as="a" href="/pricing" target="_blank" rel="noreferrer noopener nofollow">
                        Start your free trial
                      </TrialButton>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }}>
                <LocalImage
                  src={imageHeader}
                  width="100%"
                  mx="auto"
                  maxWidth="467px"
                  alt="Effectively Manage Multi-Location Brands"
                />
              </Flex>
            </Grid>

            <Flex
              mt={{ mobile: 'l', tablet: 'm' }}
              pt="m"
              flexDirection="column"
              alignItems="center"
              mb={{ mobile: 'l', desktop: 'l' }}
            >
              <G2FeaturesComponent />
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Social media tools made for{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                creators & influencers
              </H1>
            </H3>
          </Flex>
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
            mt="l"
            pt="m"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 1 }} maxWidth="429px" width="100%">
              <LocalImage
                src={imageSocialMediaTools}
                width="100%"
                alt="Social media tools made for creators & influencers"
              />
            </Flex>
            <Flex
              order={{ mobile: 1, desktop: 2 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
            >
              <Grid
                gridGap="l"
                gridTemplateColumns={{
                  mobile: 'repeat(1, 1fr)',
                  tablet: 'repeat(2, 1fr)',
                  desktop: '350px 255px',
                }}
              >
                {CREATORS_INFLUENCERS_ITEMS.map(({ picture_url, title, description }) => (
                  <Flex flexDirection="column" key={title} width="100%">
                    <Flex alignItems="center">
                      <LocalImage src={picture_url} width="44px" height="44px" alt={title} />
                      <Text ml="m" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="l" textAlign="left">
                        {title}
                      </Text>
                    </Flex>
                    <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                      {description}
                    </Text>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 2 }} maxWidth="605px" width="100%">
              <LocalImage src={imageContentPlanningTools} width="100%" alt="Content planning tools" />
            </Flex>
            <Flex
              order={{ mobile: 1, desktop: 1 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
              maxWidth={{ mobile: '100%', desktop: '450px' }}
            >
              <Text
                fontWeight="bold"
                fontSize="3xl"
                color={COLOR_CONSTANTS.DENIM}
                textAlign={{ mobile: 'center', desktop: 'left' }}
              >
                Content planning{' '}
                <Text as="span" fontWeight="bold" fontSize="3xl" color="primary">
                  tools
                </Text>
              </Text>

              <Text mt="m" pt="s" color="secondaryText" fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
                Plan you Instagram and TikTok feeds with ease using built-in visual planners
              </Text>
              <Grid mt="l" pt="m" gridGap="40px" gridTemplateColumns="repeat(1, 1fr)">
                {CONTENT_PLANNING_TOOLS_ITEMS.map(({ title, description }) => (
                  <Flex key={title}>
                    <Flex
                      width="8px"
                      bg={colors.primary}
                      minWidth="8px"
                      borderRadius={`0px ${radius.l} ${radius.l} 0px`}
                    />
                    <Flex ml="m" flexDirection="column">
                      <Text fontWeight="bold" fontSize="l" textAlign="left" color={COLOR_CONSTANTS.DENIM}>
                        {title}
                      </Text>
                      <Text mt="s" fontSize="m" color="secondaryText" textAlign="left">
                        {description}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
          <Flex mt="l" pt="s" flexDirection="column">
            <SocialsIntegrations />
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg="radial-gradient(51.88% 56.86% at 31.54% 45.31%, #0063E3 36.77%, #0250C9 100%)">
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 2 }} maxWidth="504px" width="100%">
              <LocalImage
                src={imageContentPlanningCreateShareGrow}
                width="100%"
                alt="All the tools you need to create, share, & grow"
              />
            </Flex>
            <Flex
              order={{ mobile: 1, desktop: 1 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
            >
              <Text fontWeight="bold" fontSize="4xl" color="white" textAlign={{ mobile: 'center', desktop: 'left' }}>
                All the tools you need{' '}
                <Flex
                  as="span"
                  fontWeight="bold"
                  fontSize="4xl"
                  color="white"
                  textAlign={{ mobile: 'center', desktop: 'left' }}
                  display={{ mobile: 'inline', desktop: 'block' }}
                >
                  to create, share, & grow
                </Flex>
              </Text>

              <Flex mt="m" maxWidth={{ mobile: '100%', desktop: '450px' }}>
                <Text color={COLOR_CONSTANTS.SALT} fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
                  Get the best creator tools and features with a tap of a button with Vista Social mobile app.
                </Text>
              </Flex>

              <Flex mt="l" pt="m">
                <Flex height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}>
                  <StyledGetStartedButton as="a" href="/pricing">
                    Get started now
                  </StyledGetStartedButton>
                </Flex>
              </Flex>

              <Flex
                mt={{ mobile: 'xl', desktop: 'xxl' }}
                alignItems="center"
                justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                flexDirection={{ mobile: 'column', mobileLarge: 'row' }}
              >
                <Text as="a" href={DOWNLOAD_APP_GOOGLE_LINK} target="_new" rel="noopener noreferrer nofollow">
                  <DownloadImg src={downloadGoogle} />
                </Text>

                <Text
                  ml={{ mobile: 0, mobileLarge: 'm' }}
                  mt={{ mobile: 'm', mobileLarge: 0 }}
                  as="a"
                  href={DOWNLOAD_APP_APPLE_LINK}
                  target="_new"
                  rel="noopener noreferrer nofollow"
                >
                  <DownloadImg src={downloadIOS} />
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems={{ mobile: 'center', desktop: 'flex-start' }}
          >
            <Flex mt={{ mobile: 'xl', desktop: 's' }} order={{ mobile: 2, desktop: 3 }} maxWidth="445px" width="100%">
              <Flex flexDirection="column" alignItems={{ mobile: 'center', desktop: 'flex-start' }}>
                <Text
                  textAlign="left"
                  fontWeight="medium"
                  fontSize="l"
                  color={COLOR_CONSTANTS.DENIM}
                  fontStyle="italic"
                >
                  «I absolutely love the Vista Social Planner and how easy it is to get a view of my grid and move
                  around my posts before publishing them»
                </Text>
                <Flex
                  mt="m"
                  width="100%"
                  alignItems={{ mobile: 'center', tablet: 'flex-start  ' }}
                  justifyContent={{ mobile: 'space-between', tablet: 'inherit' }}
                >
                  <Flex flexDirection="column">
                    <Text color="secondaryText" fontWeight="bold" fontSize="l" textAlign="left">
                      JOSELYN FLORES
                    </Text>
                    <Text color="secondaryText" fontSize="xs" textAlign="left">
                      Content Creator
                      <br />
                      and SMM Consultant
                    </Text>
                  </Flex>
                  <Flex maxWidth={{ mobile: '100px', tablet: '293px' }}>
                    <LocalImage
                      src={imageReviewPerson}
                      width="100%"
                      alt="«I absolutely love the Vista Social Planner and how easy it is to get a view of my grid and move around my posts before publishing them»"
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex width="2px" display={{ mobile: 'none', desktop: 'flex' }} bg="#B6CEF0" order={2} height="362px" />
            <Flex
              order={{ mobile: 1, desktop: 1 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
              mt={{ mobile: 0, desktop: 's' }}
            >
              <Text
                fontWeight="bold"
                fontSize="4xl"
                color={COLOR_CONSTANTS.DENIM}
                textAlign={{ mobile: 'center', desktop: 'left' }}
              >
                Get Started for{' '}
                <Text fontWeight="bold" fontSize="4xl" color="primary" as="span">
                  Free
                </Text>
              </Text>

              <Flex mt="m" maxWidth={{ mobile: '100%', desktop: '475px' }}>
                <Text color="secondaryText" fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
                  Try all the features Vista Social risk-free for 14 days
                  <br />
                  No credit card required
                </Text>
              </Flex>

              <Flex mt="l" pt="m">
                <Flex height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}>
                  <StyledTryVistaSocialButton as="a" href="/pricing">
                    Get started now
                  </StyledTryVistaSocialButton>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <G2FeaturesComponent textAlign={{ mobile: 'center', desktop: 'left' }} imageWidth="85px" imageHeight="110px" />

        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Frequently Asked{' '}
            <H3 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Questions
            </H3>
          </H3>
          <Flex flexDirection="column" mt="xl" width="100%">
            <GridDropdown faqs={FREQUENTLY_ASKED_QUESTIONS.reports} />
            <Flex mt="l" justifyContent="flex-end">
              <StyledLinkText as="a" href="/faq" textAlign="left">
                <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                  ALL OUR FAQS
                </Text>
                <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
              </StyledLinkText>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Footer />
    </App>
  )
}

export default SolutionsCreators

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { G2_FEATURES } from 'consts'
import { COLOR_CONSTANTS } from 'theme'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import Link from 'components/Link'

import networkImageFacebook from 'static/images/networks/facebook.svg'
import networkImageInstagtam from 'static/images/networks/instagram.svg'
import networkImageLinkedIn from 'static/images/networks/linkedin.svg'
import networkImageTwitter from 'static/images/networks/twitter.svg'
import networkImageGoogle from 'static/images/networks/googlebusiness.svg'
import networkImageTikTok from 'static/images/networks/tiktok.svg'
import networkImageYouTube from 'static/images/networks/youtube.svg'
import networkImageReddit from 'static/images/networks/reddit.svg'
import networkImagePinterest from 'static/images/networks/pinterest.svg'
import networkImageThreads from 'static/images/networks/threads2.svg'
import networkImageSnap from 'static/images/networks/snapchat2.svg'
import networkImageTumblr from 'static/images/networks/tumblr2.svg'

const NETWORKS = [
  { image: networkImageFacebook, pagePath: '/integrations/facebook' },
  { image: networkImageInstagtam, pagePath: '/integrations/instagram' },
  { image: networkImageThreads, pagePath: '/integrations/threads' },
  { image: networkImageLinkedIn, pagePath: '/integrations/linkedin' },
  { image: networkImageTwitter, pagePath: '/integrations/twitter' },
  { image: networkImageTikTok, pagePath: '/integrations/tiktok' },
  { image: networkImageGoogle, pagePath: '/integrations/google' },
  { image: networkImagePinterest, pagePath: '/integrations/pinterest' },
  { image: networkImageYouTube, pagePath: '/integrations/youtube' },
  { image: networkImageReddit, pagePath: '/integrations/reddit' },
  { image: networkImageSnap, pagePath: '/integrations/snapchat' },
  { image: networkImageTumblr, pagePath: '/integrations/tumblr' },
]

const SocialsIntegrations = () => {
  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" mx="auto" maxWidth="705px" width="100%">
        <Text my="m" pt="m" textAlign="center" fontWeight="bold" fontSize="l" color={COLOR_CONSTANTS.DENIM}>
          Vista Social integrates with the social platforms{' '}
          <Text as="span" color="primary" fontWeight="bold" fontSize="l">
            that matter to your business
          </Text>
        </Text>
        <Flex
          flexWrap="wrap"
          alignItems="center"
          justifyContent={{ mobile: 'center', mobileLarge: 'space-between' }}
          width="100%"
        >
          {NETWORKS.map(({ image, pagePath }, index) => (
            <Link
              mt="s"
              mr={{ mobile: index !== NETWORKS.length - 1 ? 'm' : 0, mobileLarge: 0 }}
              href={pagePath}
              key={pagePath}
            >
              <LocalImage
                src={image}
                alt="Your posts for the best performance on each social network."
                width="40px"
                height="40px"
              />
            </Link>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

SocialsIntegrations.defaultProps = {}

SocialsIntegrations.propTypes = {}

export default SocialsIntegrations
